export default [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    name: "Home",
    meta: {name: "Home", title: "홈"},
  },
  {
    path: "/privacy",
    component: () => import("../views/Privacy.vue"),
    name: "privacy",
    meta: {name: "privacy", title: "약관"},
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  }
];
